<script>
import {mapGetters} from "vuex";

export default {
    name: "personal-information-card",

    props: {
        user: {
            type: Object
        }
    },

    data: function () {
        return {
            model: {}
        }
    },

    computed: {
        ...mapGetters({
            profile: 'user/profile',
            loading: 'user/loading'
        }),

        item: function () {
            return this.user || this.profile
        },
    },
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('users.personal_information').ucFirst() }}</h4>
            </div>
            <div class="card-body">
                <p class="font-size-15 mb-2">{{ item?.introduction?.greeting }}</p>

                <div class="font-size-15 mb-2" v-html="item?.introduction?.introduction"></div>

                <div class="text-muted" v-html="item?.introduction?.interests"></div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </b-overlay>
</template>

<style scoped>

</style>

<script>
import {mapGetters} from "vuex";
import StickyNote from "./sticky-note.vue";

export default {
    name: "reminders",

    components: {StickyNote},

    props: {
        id: {
            type: [String, Number]
        }
    },

    computed: {
        ...mapGetters({
            loading: 'reminder/mineLoading',
            reminders: 'reminder/mine',
            user: 'user/profile',
            authenticated: 'user/authenticated'
        }),

        owner: function () {
            return this.user?.id === this.authenticated?.id;
        },
    },

    methods: {
        fetchReminders: function () {
            this.$store.dispatch('reminder/mine', {id: this.id, query: {}})
        },

        getLinkNameByModel: function (model, id) {
            let tk = '';

            switch (model) {
                case 'Appon\\Projects\\Models\\Project':
                     tk = '/projects/accessible-projects/:id(\\d+)';
                     break;
                case 'Appon\\Tasks\\Models\\Task':
                    tk = '/tasks/accessible-tasks/:id(\\d+)';
                    break;
                case 'Appon\\Partners\\Models\\Partner':
                    tk = '/partners/:id(\\d+)/:type?';
                    break;
            }

            return tk ? this.$t('routes.'+tk, {id: id}).rmValidation() : null;
        },

        markAsRead: function (id) {
            this.$store.dispatch('reminder/markAsRead', {reminder_id: id, user_id: this.authenticated.id});
        },
    },

    watch: {
        id: {
            deep: true,
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.fetchReminders();
                }
            },
        }
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="row mt-3 mb-5">
            <div class="col col-lg-4 sticky-note-wrapper" v-for="(reminder, index) in reminders" :key="index">
                <sticky-note :title="reminder.title"
                             :message="reminder.message"
                             :due="reminder.due_next"
                             :users="reminder.remindables"
                >
                    <template #header>
                        <div v-if="owner" @click="markAsRead(reminder.id)" class="position-absolute rotate right-custom top-custom pb-1 pt-2 px-4 bg-soft-success">
                            <i class="fas fa-eye text-success"></i>
                        </div>
                    </template>
                    <template #reference>
                        <router-link v-if="reminder.reference_type" :to="getLinkNameByModel(reminder.reference_type, reminder.reference_id)">
                            <i class="fas fa-external-link-alt"></i>
                        </router-link>
                    </template>
                </sticky-note>
            </div>
        </div>
    </b-overlay>
</template>

<style lang="scss" scoped>
.rotate {
    top: 0;
    right: 0;
    transform: translate(35%, -20%) rotateZ(+45deg);
    opacity: 0.5;
    i {
        transition: 0.3s;
        transform: translate(-10%, 10%) rotateZ(-45deg);
    }
    &:hover {
        opacity: 1;
        i {
            scale: 1.2;
        }
    }
}
</style>

<script>
export default {
    name: "statistic-card",

    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number],
            required: true
        },
        iconClass: {
            type: [String],
            default: 'fas fa-question-circle'
        },
        badgeText: {
            type: String
        },
        variant: {
            type: String,
            default: 'primary'
        },
        badgeColor: {
            type: String,
            default: 'primary'
        },
        loading: {
            type: Boolean
        }
    },

    computed: {
        background: function () {
            return 'bg-' + this.variant;
        },
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                            <div class="avatar-title rounded bg-gradient" :class="background">
                                <slot name="icon">
                                    <i class="font-size-26" :class="iconClass"></i>
                                </slot>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <p class="text-muted mb-1">{{ title?.ucFirst() }}</p>
                        <h4 class="mb-0">{{ value }}</h4>
                    </div>

                    <div class="flex-shrink-0 align-self-end ms-2">
                        <slot name="right">
                            <div
                                class="badge rounded-pill font-size-13"
                                :class="'badge-soft-' + badgeColor"
                            >
                                {{ badgeText }}
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </b-overlay>
</template>

<style scoped>

</style>

<script>
import PartnerForm from "../../forms/partner-form.vue";
import DecisionMakerManager from "../../../views/pages/decision-makers/manage.vue";
import ContactManager from "../../../views/pages/contacts/manage.vue";
import {mapGetters} from "vuex";

export default {
    name: "partner-modal",

    components: {ContactManager, DecisionMakerManager, PartnerForm},

    emits: ['close', 'update:modelValue', 'modified'],

    props: {
        modelValue: {
            type: Boolean,
            default: false
        },

        title: {
            type: String
        },

        projectStep: {
            type: Object,
            default: null
        },

        tab: {
            type: String,
            default: 'partner'
        },

        id: {
            type: Number,
            default: null
        },

        partner: {
            type: Object,
            default: null
        },

        danger: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            storePartner: 'partner/item',
            requiredRelations: 'projectStep/relations',
            requiredFields: 'projectStep/fields',
            requirements: 'projectStep/requirements'
        }),

        modalShow: {
            get: function () {
                return this.modelValue;
            },

            set: function (value) {
                this.$emit('update:modelValue', value);
            },
        },
    },

    methods: {
        fetchPartner: function () {
            if (this.partner) {
                this.partnerModel = JSON.parse(JSON.stringify(this.partner));
            } else if (this.id) {
                this.$store.dispatch('partner/show', {id: this.id}).then(() => {
                    this.partnerModel = JSON.parse(JSON.stringify(this.storePartner));
                });
            }
        },

        fetchRequirements: function () {
            this.$store.dispatch('projectStep/requirements', this.projectStep?.id).then(() => {
                this.requiredFields = this.$store.getters['projectStep/requiredFields']('partner');
                this.contactRequiredFields = this.$store.getters['projectStep/requiredFields']('contact');
                this.decisionMakerRequiredFields = this.$store.getters['projectStep/requiredFields']('decisionMaker');
                this.missingPartner = this.requiredFields?.length > 0;
                this.missingContact = this.$store.getters['projectStep/requiredFields']('contact')?.length || this.requiredRelations?.['partner']?.includes('contact');
                this.missingDecisionMaker = this.$store.getters['projectStep/requiredFields']('decisionMaker')?.length || this.requiredRelations?.['partner']?.includes('decisionMaker');
            });
        },

        close: function () {
            this.modalShow = false;
            this.$emit('close');
        },

        refresh: function () {

            this.fetchRequirements();
            this.$emit('modified');
        },
    },

    data: function () {
        return {
            currentTab: 'partner',
            partnerModel: {},
            requiredFields: [],
            contactRequiredFields: [],
            decisionMakerRequiredFields: [],
            missingPartner: false,
            missingContact: false,
            missingDecisionMaker: false,
        };
    },

    watch: {
        tab: {
            immediate: true,
            handler: function (tab) {
                this.currentTab = tab;
            },
        },

        partner: {
            immediate: true,
            handler: function () {
                this.fetchPartner();
            },
        },

        id: {
            immediate: true,
            handler: function () {
                this.fetchPartner();
            },
        },

        projectStep: {
            immediate: true,
            handler: function (projectStep) {
                if (projectStep?.id) {
                    this.fetchRequirements();
                }
            },
        }
    }
}
</script>

<template>
    <b-modal size="xl" body-bg-variant="light" body-class="rounded" v-model="modalShow" :title="title" hide-footer>
        <template #title v-if="danger">
            <i class="fas fa-exclamation-triangle me-2 text-danger"></i>
            <span class="font-weight-bold text-danger">{{ title }}</span>
        </template>
        <div class="d-flex mb-4">
            <div class="w-100 text-center cursor-pointer rounded-top py-2" :class="{'text-primary bg-soft-primary': currentTab === 'partner'}" @click="currentTab = 'partner'">
                <i class="fas fa-home me-2" :class="{'text-danger': missingPartner}"></i>
                <span :class="{'text-danger font-weight-bold': missingPartner}">{{ $tc('partners::partners.partner', 1).ucFirst() }}</span>
            </div>
            <div class="w-100 text-center cursor-pointer rounded-top py-2" :class="{'text-primary bg-soft-primary': currentTab === 'contact'}" @click="currentTab = 'contact'">
                <i class="fas fa-address-card me-2" :class="{'text-danger': missingContact}"></i>
                <span :class="{'text-danger font-weight-bold': missingContact}">{{ $tc('partners::contacts.contact', 2).ucFirst() }}</span>
            </div>
            <div class="w-100 text-center cursor-pointer rounded-top py-2" :class="{'text-primary bg-soft-primary': currentTab === 'decision'}" @click="currentTab = 'decision'">
                <i class="fas fa-user-tie me-2" :class="{'text-danger': missingDecisionMaker}"></i>
                <span :class="{'text-danger font-weight-bold': missingDecisionMaker}">{{ $tc('partners::decision_makers.decision_maker', 2).ucFirst() }}</span>
            </div>
        </div>
        <partner-form v-if="currentTab === 'partner' && partnerModel?.id" :partner="partnerModel" :required-fields="requiredFields" @saved="refresh">
            <template #buttons>
                <b-button class="ms-2" variant="secondary" @click="close">{{ $t('base.close').ucFirst() }}</b-button>
            </template>
        </partner-form>
        <contact-manager v-if="currentTab === 'contact'" :required-fields="contactRequiredFields" :partner-id="partnerModel?.id" @saved="refresh"></contact-manager>
        <decision-maker-manager v-if="currentTab === 'decision'" :required-fields="decisionMakerRequiredFields" :partner-id="partnerModel?.id" @saved="refresh"></decision-maker-manager>
    </b-modal>
</template>

<style scoped>

</style>
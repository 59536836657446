<script>
import Avatar from "./avatar.vue";

export default {
    name: "team-card",

    components: {Avatar},

    props: {
        team: {
            type: Object,
            required: true
        },

        loading: {
            type: Boolean
        }
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-header py-2">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="line">
                        <h5 class="card-title mb-1">
                            {{ team?.translation_key ? $t(team.translation_key).ucFirst() : team?.name }}
                        </h5>
                        <p class="mb-0">
                            <small v-if="team?.division" class="me-2">
                                <i v-if="team.division?.icon" class="me-1" :class="team.division.icon" :style="{color: team.division.color}"></i>
                                <span>{{ team.division.translation_key ? $t(team.division.translation_key).ucFirst() : team.division.name }}</span>
                            </small>
                            <small v-if="team?.division && team?.department" class="me-2">/</small>
                            <small v-if="team?.department" class="">
                                <i v-if="team.department?.icon" class="me-1" :class="team.department.icon" :style="{color: team.department.color}"></i>
                                <span>{{ team.department.translation_key ? $t(team.department.translation_key).ucFirst() : team.department.name }}</span>
                            </small>
                        </p>
                    </div>
                    <div v-if="team?.icon" class="me-3 font-size-28" :class="{'text-primary': !team?.color}">
                        <i :class="team?.icon" :style="{color: team?.color}"></i>
                    </div>
                </div>
            </div>
            <div class="card-body py-2 bg-light">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="me-3">
                        <avatar :user="team?.leader" size="md"></avatar>
                    </div>
                    <div v-if="team?.members?.length" class="">
                        <div class="avatar-group">
                            <avatar v-for="(member) in team?.members" :key="member.id" class="avatar-group-item" :user="member" size="sm"></avatar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer bg-light p-1">
                <p class="mb-0 text-center">
                <span v-for="(location) in team?.locations" class="font-size-12" :key="location.id">
                    <i v-if="location.icon" class="me-1" :class="location.icon" :style="{color: location.color}"></i>
                    <span class="me-2">{{ location.translation_key ? $t(location.translation_key).ucFirst() : location.name }}</span>
                    <span>{{ location.address }}</span>
                </span>
                </p>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>

</style>

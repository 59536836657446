<script>
import {mapGetters} from "vuex";

export default {
    name: "weekly-performance",

    props: {
        owner: {
            type: Boolean,
            default: false
        },
        id: {
            type: [String, Number]
        }
    },

    data: function () {
        return {
            linkables: [
                'interactions::interactions.interactions.contract',
                'interactions::interactions.interactions.implement',
                'interactions::interactions.interactions.invoicing',
                'interactions::interactions.interactions.meeting',
                'interactions::interactions.interactions.offer',
            ]
        };
    },

    computed: {
        ...mapGetters({
            loading: 'performance/weeklyLoading',
            performance: 'performance/weekly'
        }),

        currentWeek: function () {
            return this.performance?.current_week || {};
        },

        lastWeek: function () {
            return this.performance?.last_week || {};
        },

        difference: function () {
            return this.performance?.difference || {};
        },

        downward: function () {
            return Math.sign(this.difference?.summary || 0) === -1;
        },

        rising: function () {
            return Math.sign(this.difference?.summary || 0) === 1;
        },
    },

    methods: {
        fetch: function () {
            if (this.id) {
                this.$store.dispatch('performance/weekly', this.id);
            }
        },

        hasLink: function (translationKey) {
            return this.linkables.includes(translationKey);
        },

        link: function (translationKey) {
            switch (translationKey) {
                case 'interactions::interactions.interactions.contract':
                    return this.$t('routes./interactions/my-contracts');
                case 'interactions::interactions.interactions.implement':
                    return this.$t('routes./interactions/my-implements');
                case 'interactions::interactions.interactions.invoicing':
                    return this.$t('routes./interactions/my-invoices');
                case 'interactions::interactions.interactions.meeting':
                    return this.$t('routes./interactions/my-meetings');
                case 'interactions::interactions.interactions.offer':
                    return this.$t('routes./interactions/my-offers');
                // case 'interactions::interactions.interactions.phone':
                // case 'interactions::interactions.interactions.document':
                // case 'interactions::interactions.interactions.email':
                default:
                    return null;
            }
        }
    },

    watch: {
        id: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.fetch();
                }
            }
        }
    },

    unmounted: function () {
        this.$store.dispatch('performance/clearWeekly');
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card overflow-hidden">
            <div class="bg-soft-primary text-center text-primary">
                <small>
                    <strong>{{ $t('projects::performance.weekly_performance').ucFirst() }}</strong>
                </small>
            </div>
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                            <div class="avatar-title rounded bg-gradient bg-primary">
                                <slot name="icon">
                                    <i class="font-size-26 fas fa-calendar-week"></i>
                                </slot>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <h4 class="mb-0 text-center opacity-50">{{ lastWeek?.summary || 0 }}</h4>
                        <p class="text-muted mb-1 text-center font-size-10">{{ $t('projects::performance.last_week').ucFirst() }}</p>
                    </div>
                    <div class="flex-grow-1">
                        <h4 class="mb-0 text-center" :class="{'text-success': currentWeek?.summary > lastWeek?.summary, 'text-danger': lastWeek?.summary > currentWeek?.summary}">{{ currentWeek?.summary || 0 }}</h4>
                        <p class="text-muted mb-1 text-center font-size-10">{{ $t('projects::performance.this_week').ucFirst() }}</p>
                    </div>

                    <div class="flex-shrink-0 ms-2">
                        <div class="d-flex">
                            <div class="d-flex justify-content-end ms-2" v-if="difference?.summary !== 0">
                                <div
                                    class="d-flex rounded align-items-center p-2 bg-gradient"
                                    :class="{'bg-soft-danger text-danger': downward, 'bg-soft-success text-success': rising}"
                                >
                                    <strong>{{ difference?.summary_percentage }}%</strong>
                                    <i class="fas ms-2" :class="{'fa-angle-double-up': rising, 'fa-angle-double-down': downward}"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-top mt-3 pt-3">
                    <table class="table align-middle table-nowrap mb-0">
                        <tbody>
                        <tr v-for="(interaction, index) in performance?.interactions || []" :key="index">
                            <td>
                                <template v-if="owner">
                                    <template v-if="hasLink(interaction.translation_key)">
                                        <router-link :to="link(interaction.translation_key)">
                                        <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                              :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                        </span>
                                        </router-link>
                                    </template>
                                    <template v-else>
                                    <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                          :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                    </span>
                                    </template>
                                </template>
                                <template v-else>
                                    <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                          :title="$t(interaction.translation_key).ucFirst()"
                                    >
                                        <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                    </span>
                                </template>
                            </td>
                            <td>

                            </td>
                            <td style="width: 1%">
                                <div class="bg-soft-light rounded px-2 text-muted">
                                    <div class="font-size-12 line-height-normal d-flex justify-content-between">
                                        <small class="me-2">{{ $t('projects::performance.last_week').ucFirst() }}:</small>
                                        <small class="">{{ interaction?.last_week?.total?.count || 0 }}</small>
                                    </div>
                                    <div class="font-size-12 line-height-normal d-flex justify-content-between mb-1">
                                        <small class="me-2">{{ $t('projects::performance.this_week').ucFirst() }}:</small>
                                        <small>{{ interaction?.current_week?.total?.count || 0 }}</small>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span v-if="interaction?.target_value" :title="$tc('projects::target_values.weekly_target_value', 2).ucFirst()" class="bull-eye ms-2" :class="{
                                    'bull-eye-muted opacity-50': interaction?.target_value === 0,
                                    'bull-eye-success': interaction?.target_value >= interaction?.current_week?.total?.count && interaction?.target_value > 0,
                                    'bull-eye-danger': interaction?.current_week?.total?.count === 0 && interaction?.target_value > 0,
                                    'bull-eye-warning': interaction?.current_week?.total?.count < interaction?.target_value && interaction?.current_week?.total?.count > 0
                                }">
                                    {{ interaction?.current_week?.total?.count }}
                                </span>
                            </td>
                            <td>
                                <div
                                    v-if="interaction?.difference?.total?.count"
                                    class="d-flex align-items-center justify-content-end"
                                >
                                    <div class="bg-gradient rounded p-2"
                                         :class="{'bg-soft-danger text-danger': Math.sign(interaction?.difference?.total?.count || 0) === -1, 'bg-soft-success text-success': Math.sign(interaction?.difference?.total?.count || 0) === 1}"
                                    >
                                        <strong>{{ interaction?.difference?.total?.count_percentage }}%</strong>
                                        <i class="fas ms-2" :class="{'fa-angle-double-up': rising, 'fa-angle-double-down': downward}"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>
    .min-width-40px {
        min-width: 40px;
    }
</style>

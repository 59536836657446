<script>
export default {
    name: "personal-quantity-targets-card",

    props: {
        user: {
            type: Object
        }
    },

    data: function () {
        return {
            performance: null,
            years: [],
            months: [],
        };
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler:function(user) {
                if (user?.id) {
                    this.$store.dispatch('performance/personalPerformanceWithTargetValues', user.id).then(response => {
                        this.performance = response.data;
                        this.years = [...response.years];
                        this.months = response.months;
                    });
                }
            }
        },
    }
}
</script>

<template>
    <div class="row performance">
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title text-center text-muted">
                        <i class="fas fa-calendar-alt me-2"></i>
                        <span>{{ $t('dates.yearly').ucFirst() }}</span>
                    </h5>
                </div>
                <div class="card-body">
                    <table class="table align-middle table-striped table-hover table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{ $t('base.all').ucFirst() }}</th>
                            <th v-for="(year) in years" :key="year">{{ year }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(interaction, index) in performance || []" :key="index">
                            <td>
                                <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                      :title="$t(interaction.translation_key).ucFirst()"
                                >
                                    <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                </span>
                            </td>
                            <td>
                                <strong :title="$t('projects::performance.weekly_performance').ucFirst()">{{ interaction?.count }}</strong>
                            </td>
                            <td v-for="(yearly) in interaction?.yearly" :key="yearly.text">
                                {{ yearly.count }}
                            </td>
                            <td>
                                <span :title="$tc('projects::target_values.target_value', 2).ucFirst()" class="bull-eye ms-2" :class="{
                                    'bull-eye-muted opacity-50': interaction?.target_values?.yearly === 0,
                                    'bull-eye-success': interaction?.count >= interaction?.target_values?.yearly && interaction?.target_values?.yearly > 0,
                                    'bull-eye-danger': interaction?.count === 0 && interaction?.target_values?.yearly > 1,
                                    'bull-eye-warning': interaction?.count < interaction?.target_values?.yearly && interaction?.count > 0
                                }">
                                    {{ interaction?.target_values?.yearly }}
                                </span>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-8">
            <div class="row" v-for="(year) in years.reverse()" :key="year">
                <div class="col-12 col-lg-4" v-for="(month, number) in months[year]" :key="number">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                <div class="d-flex align-items-center">
                                    <div class="me-3">
                                        <i class="fas fa-calendar-alt font-size-34 text-muted opacity-50"></i>
                                    </div>
                                    <div>
                                        <p class="mb-0">
                                            <small>{{ year }}.</small>
                                        </p>
                                        <h5 class="mb-0">{{ number.printMonth() }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm align-middle table-striped table-hover table-nowrap mb-0">
                                <tbody>
                                <tr v-for="(interaction, index) in performance || []" :key="index">
                                    <td>
                                        <span class="d-inline-flex align-items-center justify-content-center text-center border border-white rounded bg-gradient bg-soft-primary text-primary cube-20px"
                                              :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-10" :class="interaction.icon"></i>
                                        </span>
                                    </td>
                                    <td>
                                        {{ interaction?.monthly?.[year]?.[number]?.count }}
                                    </td>
                                    <td>
                                    <span :title="$tc('projects::target_values.target_value', 2).ucFirst()" class="bull-eye ms-2" :class="{
                                        'bull-eye-muted opacity-50': interaction?.target_values?.monthly === 0,
                                        'bull-eye-success': interaction?.monthly?.[year]?.[number]?.count >= interaction?.target_values?.monthly && interaction?.target_values?.monthly > 0,
                                        'bull-eye-danger': interaction?.monthly?.[year]?.[number]?.count === 0 && interaction?.target_values?.monthly > 1,
                                        'bull-eye-warning': interaction?.monthly?.[year]?.[number]?.count < interaction?.target_values?.monthly && interaction?.monthly?.[year]?.[number]?.count > 0
                                    }">
                                    {{ interaction?.target_values?.monthly }}
                                </span>
                                    </td>

                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.min-width-40px {
    min-width: 40px;
}
.cube-20px {
    width: 20px;
    height: 20px;
}
</style>

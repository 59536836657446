<script>
import {mapGetters} from "vuex";

export default {
    name: "performance",

    props: {
        owner: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'performance/loading',
            performance: 'performance/item'
        }),

        downward: function () {
            return Math.sign(this.performance?.diff_between_last_and_current_week|| 0) === -1
        },

        rising: function () {
            return Math.sign(this.performance?.diff_between_last_and_current_week|| 0) === 1
        },
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                        <div class="avatar">
                            <div class="avatar-title rounded bg-gradient bg-success">
                                <slot name="icon">
                                    <i class="font-size-26 fas fa-check-double"></i>
                                </slot>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <p class="text-muted mb-1">{{ $t('projects::performance.total').ucFirst() }}</p>
                        <h4 class="mb-0">{{ performance?.summary }}</h4>
                    </div>

                    <div class="flex-shrink-0 ms-2">
                        <div class="d-flex">
                            <div class="d-flex flex-column justify-content-center bg-soft-light rounded px-2 text-muted">
                                <div class="font-size-12 line-height-normal d-flex justify-content-between">
                                    <small class="me-2">{{ $t('projects::performance.last_week').ucFirst() }}:</small>
                                    <small class="">{{ performance?.last_week_summary || 0 }}</small>
                                </div>
                                <div class="font-size-12 line-height-normal d-flex justify-content-between">
                                    <small class="me-2">{{ $t('projects::performance.this_week').ucFirst() }}:</small>
                                    <small>{{ performance?.current_week_summary || 0 }}</small>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end ms-2" v-if="performance?.diff_between_last_and_current_week !== 0">
                                <div
                                    class="d-flex rounded align-items-center p-2 bg-gradient"
                                    :class="{'bg-soft-danger text-danger': downward, 'bg-soft-success text-success': rising}"
                                >
                                    <strong>{{ performance?.diff_percentage }}%</strong>
                                    <i class="fas ms-2" :class="{'fa-angle-double-up': rising, 'fa-angle-double-down': downward}"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-top mt-3 pt-3">
                    <table class="table align-middle table-nowrap mb-0">
                        <tbody>
                        <tr v-for="(interaction, index) in performance?.interactions || []" :key="index">
                            <td>
                                <template v-if="owner">
                                    <template v-if="interaction.name === 'meeting'">
                                        <router-link :to="$t('routes./interactions/my-meetings')">
                                        <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                              :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                        </span>
                                        </router-link>
                                    </template>
                                    <template v-else-if="interaction.name === 'offer'">
                                        <router-link :to="$t('routes./interactions/my-offers')">
                                        <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                              :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                        </span>
                                        </router-link>
                                    </template>
                                    <template v-else-if="interaction.name === 'contract'">
                                        <router-link :to="$t('routes./interactions/my-contracts')">
                                        <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                              :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                        </span>
                                        </router-link>
                                    </template>
                                    <template v-else-if="interaction.name === 'implement'">
                                        <router-link :to="$t('routes./interactions/my-implements')">
                                        <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                              :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                        </span>
                                        </router-link>
                                    </template>
                                    <template v-else-if="interaction.name === 'invoicing'">
                                        <router-link :to="$t('routes./interactions/my-invoices')">
                                        <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                              :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                        </span>
                                        </router-link>
                                    </template>
                                    <template v-else>
                                    <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                          :title="$t(interaction.translation_key).ucFirst()"
                                        >
                                            <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                    </span>
                                    </template>
                                </template>
                                <template v-else>
                                    <span class="d-inline-block text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary min-width-40px"
                                          :title="$t(interaction.translation_key).ucFirst()"
                                    >
                                        <i v-if="interaction?.icon" class="font-size-18" :class="interaction.icon"></i>
                                    </span>
                                </template>
                            </td>
                            <td>
                                <strong :title="$t('projects::performance.total_performance').ucFirst()">{{ interaction?.interactions?.length }}</strong>
                            </td>
                            <td style="width: 1%">
                                <div class="bg-soft-light rounded px-2 text-muted">
                                    <div class="font-size-12 line-height-normal d-flex justify-content-between">
                                        <small class="me-2">{{ $t('projects::performance.last_week').ucFirst() }}:</small>
                                        <small class="">{{ interaction?.last_week_count || 0 }}</small>
                                    </div>
                                    <div class="font-size-12 line-height-normal d-flex justify-content-between mb-1">
                                        <small class="me-2">{{ $t('projects::performance.this_week').ucFirst() }}:</small>
                                        <small>{{ interaction?.current_week_count || 0 }}</small>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span :title="$tc('projects::target_values.weekly_target_value', 2).ucFirst()" class="bull-eye ms-2" :class="{
                                    'bull-eye-muted opacity-50': interaction?.weekly_target_value === 0,
                                    'bull-eye-success': interaction?.current_week_count >= interaction?.weekly_target_value && interaction?.weekly_target_value > 0,
                                    'bull-eye-danger': interaction?.current_week_count === 0 && interaction?.weekly_target_value > 0,
                                    'bull-eye-warning': interaction?.current_week_count < interaction?.weekly_target_value && interaction?.current_week_count > 0
                                }">
                                    {{ interaction?.weekly_target_value }}
                                </span>
                            </td>
                            <td>
                                <div
                                    v-if="interaction?.diff !== 0"
                                    class="d-flex align-items-center justify-content-end"
                                >
                                    <div class="bg-gradient rounded p-2"
                                         :class="{'bg-soft-danger text-danger': Math.sign(interaction?.diff || 0) === -1, 'bg-soft-success text-success': Math.sign(interaction?.diff || 0) === 1}"
                                    >
                                        <strong>{{ interaction?.diff_percentage ? Math.round(interaction?.diff_percentage * 100) / 100 : interaction?.diff_percentage }}%</strong>
                                        <i class="fas ms-2" :class="{'fa-angle-double-up': rising, 'fa-angle-double-down': downward}"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>
    .min-width-40px {
        min-width: 40px;
    }
</style>

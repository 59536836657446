<script>
import ProcessProjectItem from "./process-project-item.vue";

export default {
    name: "process-project-matrix",
    components: {ProcessProjectItem},

    props: {
        items: {
            type: Array
        }
    },

    computed: {
        filtered: function () {
            let items = this.items;

            if (this.column) {
                items = items.filter(i => i.step_id === this.column.id);
            }

            return items;
        },
    },

    data: function() {
        return {
            hover: null,
            column: null,
        }
    },

    methods: {
        selectColumn: function(step) {
            this.column = step;
        },
    },

}
</script>

<template>
    <div class="process-line position-relative">
        <process-project-item :process="process" v-for="(process, pIndex) in items" :key="pIndex"></process-project-item>
    </div>
</template>


<script>
import Avatar from "./avatar.vue";

export default {
    name: "partner-grid-item",
    components: {Avatar},

    props: {
        partner: {
            type: Object,
            required: true
        }
    },

    computed: {
        project: function () {
            return this.partner?.projects?.[0];
        },
    }
}
</script>

<template>
    <div class="card mb-0 h-100">
        <div class="card-body">
            <div class="d-flex">
                <router-link class="text-dark" :to="$t('routes./partners/:id(\\d+)/:type?', {id: partner.id}).rmValidation()">
                    <div class="avatar">
                        <span class="avatar-title rounded bg-light text-pink font-size-16">
                            {{ partner.name?.firstLetters() }}
                        </span>
                    </div>
                </router-link>
                <p class="text-muted align-bottom ms-4 mb-0 align-self-end opacity-50">
                    <i class="bx bxs-factory me-2"></i>
                    {{ partner.industry?.name }}
                </p>
                <div class="ms-auto">
                    <div class="dropdown float-end">
                        <a class="text-muted dropdown-toggle font-size-18" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                            <i class="bx bx-dots-horizontal-rounded"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#">Edit</a>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Remove</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column flex-grow-1 mt-4">
                <div class="">
                    <h5 class="text-truncate font-size-15">
                        <router-link class="text-dark" :to="$t('routes./partners/:id(\\d+)/:type?', {id: partner.id}).rmValidation()">
                            {{ partner.name }}
                        </router-link>
                    </h5>
                    <p class="text-muted mb-0 font-size-12">{{ partner.company_size?.translation_key ? $t(partner.company_size.translation_key).ucFirst() : partner.company_size?.name }}</p>
                </div>

                <div class="mt-3 mb-1">
                    <div class="row align-items-center" v-for="(project, projectIndex) in partner.projects" :key="projectIndex">
                        <router-link class="text-dark" :to="$t('routes./projects/accessible-projects/:id(\\d+)', {id: project.id}).rmValidation()">
                            <small>{{ project.translation_key ? $t(project.translation_key).ucFirst() : project.name }}</small>
                        </router-link>
                        <div class="col">
                            <div class="progress" style="height: 6px;">
                                <div class="progress-bar bg-progress" :class="'bg-progress-'+project?.progress_percentage" role="progressbar" :style="{width: project?.progress_percentage + '%'}" :aria-valuenow="project?.progress_percentage" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <h6 class="mb-0 font-size-13"> {{ project?.progress_percentage }}%</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-4 py-2 border-top">
            <div class="d-flex justify-content-between align-items-center">
                <div class="me-1">
                    <div class="row">
                        <div class="col">
                            <span class="d-inline-block text-center me-3" :title="$tc('projects::projects.project', 2).ucFirst()">
                                <i class="bx bx-clipboard me-1 font-size-16 align-middle"></i>
                                <span class="font-size-12">{{ partner.projects_count || 0 }}</span>
                            </span>
                            <span class="d-inline-block text-center " :title="$tc('interactions::meetings.meeting', 2).ucFirst()">
                                <i class="bx bx-calendar me-1 font-size-16 align-middle"></i>
                                <span class="font-size-12">{{ partner.meetings_count || 0 }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="d-inline-block text-center me-3" :title="$tc('tasks::tasks.task', 2).ucFirst()">
                                <i class="bx bx-list-check me-1 font-size-16 align-middle"></i>
                                <span class="font-size-12">{{ partner.tasks_count || 0 }}</span>
                            </span>
                            <span class="d-inline-block text-center" :title="$tc('reminders::reminders.reminder', 2).ucFirst()">
                                <i class="bx bxs-notepad me-1 font-size-16 align-middle"></i>
                                <span class="font-size-12">{{ partner.reminders_count || 0 }}</span>
                            </span>
                        </div>
                    </div>
                </div>
<!--                <ul class="list-inline mb-0">
                    <li class="list-inline-item me-3">
                        <i class="bx bx-paperclip me-1 font-size-16 align-middle"></i> 236
                    </li>
                    <li class="list-inline-item me-3">
                        <i class="bx bx-chat me-1 font-size-16 align-middle"></i> 202
                    </li>
                </ul>-->

                <div class="avatar-group">
                    <avatar class="avatar-group-item" v-for="(user, userIndex) in partner.users" :key="userIndex" :user="user"
                        :style="{borderColor: user?.color}"
                    ></avatar>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
export default {
    name: "process-project-item",

    props: {
        process: {
            type: Object
        }
    },

    computed: {
        filtered: function () {
            let items = this.process.projects;

            if (this.column) {
                items = items.filter(i => i.step_id === this.column.id);
            }

            if (this.search) {
                const lcSearch = this.search.toLowerCase();

                items = items.filter(i => {
                    const name = i.translation_key ? this.$t(i.translation_key) : i.name;
                    const pname = i.partner?.translation_key ? this.$t(i.partner.translation_key) : i.partner?.name;

                    return name.toLowerCase().indexOf(lcSearch) > -1 || (pname && pname.toLowerCase().indexOf(lcSearch) > -1);
                });
            }

            return items;
        },

        stepCount: function () {
            const count = {};

            this.process.steps.forEach(step => {
                count[step.id] = this.process.projects.filter(p => p.step_id === step.id)?.length;
            });

            return count;
        },
    },

    data: function() {
        return {
            hover: null,
            column: null,
            searchFocused: false,
            search: '',
        }
    },

    methods: {
        selectColumn: function(step) {
            this.column = step;
        },
    },

}
</script>

<template>
    <ol class="d-flex w-100 p-0 list-unstyled rounded-top overflow-hidden">
        <li class="flex-grow-1 bg-white ">
            <div class="row bg-soft-primary py-2 border-bottom">
                <div class="col-12 padding-y">
                    <div class="px-3">
                        <b-input-group class="ms-2">
                            <b-input-group-text class="bg-white border-right-0" :class="{focused: searchFocused}">
                                <i class="fas fa-search"></i>
                            </b-input-group-text>
                            <input class="form-control form-control-sm border-left-0"
                                   type="search"
                                   v-model="search"
                                   :placeholder="$t('base.search').ucFirst()"
                                   @focusin="searchFocused = true"
                                   @blur="searchFocused = false"
                            />
                        </b-input-group>
                    </div>
                </div>
            </div>
            <div class="row bg-soft-primary mb-3">
                <div class="col-12 line-height-custom">
                    &nbsp;
                </div>
            </div>
            <div @mouseover="hover = projectIndex" @mouseleave="hover = null" class="row py-2 height border-bottom" v-for="(project, projectIndex) in filtered" :key="projectIndex">
                <div class="col-12 text-end">
                    <div class="d-flex flex-column align-items-center">
                        <router-link :to="$t('routes./projects/accessible-projects/:id(\\d+)', {id: project.id}).rmValidation()">
                            <i class="fas fa-clipboard-list me-2"></i>
                            <strong>{{ (project.translation_key ? $t(project.translation_key) : project.name)?.ucFirst() }}</strong>
                        </router-link>
                        <router-link :to="$t('routes./partners/:id(\\d+)/:type?', {id: project.partner?.id}).rmValidation()">
                            <b-badge variant="primary">
                                <i class="fas fa-handshake me-2"></i>
                                {{ project.partner?.name }}
                            </b-badge>
                        </router-link>
                    </div>
                </div>
            </div>
        </li>
        <li class="flex-grow-1 text-center process-column" v-for="(step, sIndex) in process.steps" :key="sIndex">
            <div class="row bg-soft-primary py-3 border-bottom">
                <div class="col-12">
                    <span class="d-inline-block position-relative rounded z-index px-3 outline-soft-primary cursor-pointer"
                          :class="{'bg-white': column?.id !== step.id, 'bg-primary text-white': column?.id === step.id}"
                          @click="selectColumn(step)">{{ (step.translation_key ? $t(step.translation_key) : step.name)?.ucFirst() }}
                    </span>
                    <div class="border border-success position-absolute top-50" :class="{'w-100': sIndex > 0 && sIndex < (process.steps.length - 1), 'w-50 right-0': sIndex === 0, 'w-50 left-0': sIndex === (process.steps.length - 1)}"></div>
                </div>
            </div>
            <div class="row bg-soft-primary mb-3">
                <div class="col-12">
                    <div class="d-flex padding-y-2 align-items-center justify-content-center">
                        <span class="badge bg-white text-pink font-size-12 font-weight-bold">{{ stepCount[step.id] }}</span>
                    </div>
                </div>
            </div>
            <div class="row py-2 height border-bottom" :class="{'bg-light': hover === projectIndex}" v-for="(project, projectIndex) in filtered" :key="projectIndex">
                <div class="col-12">
                    <div class="d-flex h-100 w-100 flex-column align-items-center justify-content-center">
                        <i v-if="step.id === project.step_id" class="fas fa-arrow-alt-circle-right text-primary font-size-24"></i>
                        <i v-if="step.sort < project.step?.sort" class="fas fa-check-circle text-success font-size-24 opacity-50"></i>
                    </div>
                </div>
            </div>
        </li>
    </ol>
</template>

<style lang="scss" scoped>
    @use "@/assets/scss/bootstrap.scss" as colors;

    .line-height-custom {
        line-height: 1.6;
    }

    .z-index {
        z-index: 1;
    }

    .right-0 {
        right: 0;
    }

    .left-0 {
        left: 0;
    }

    table {
        border-radius: 8px;
    }

    thead tr:first-of-type th:first-child {
        border-top-left-radius: 8px;
    }

    thead tr:first-of-type th:last-child {
        border-top-right-radius: 8px;
    }

    th, td {
        border: 0;
    }

    .height {
        height: 60px;
    }

    .process-column:hover {
        background-color: colors.$light !important;
    }

    .padding-y {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .padding-y-2 {
        padding-top: 2px;
        padding-bottom: 3px;
    }

    .badge-positioned {
        top: 100%;
        margin-top: 2px;
        left: 50%;
        transform: translate(-50%);
    }

</style>

<script>
import {mapGetters} from "vuex";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import huLocale from '@fullcalendar/core/locales/hu';

export default {
    name: "meetings",

    components: {
        FullCalendar,
    },

    computed: {
        ...mapGetters({
            loading: 'meeting/mineLoading',
            meetings: 'meeting/mine',
            user: 'user/profile'
        }),

    },

    data: function () {
        return {
            calendarOptions: {
                locale: huLocale,
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                },
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    bootstrapPlugin,
                    listPlugin,
                ],
                initialView: "dayGridMonth",
                themeSystem: "bootstrap",
                events: this.fetchMeetings,
                // editable: true,
                // droppable: true,
                eventResizableFromStart: true,

                eventsSet: this.handleEvents,
                weekends: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
            },
            currentEvents: [],
            showModal: false,
            eventModal: false,
            categories: [
                {
                    name: 'Danger',
                    value: 'bg-danger'
                },
                {
                    name: 'Success',
                    value: 'bg-success'
                },
                {
                    name: 'Primary',
                    value: 'bg-primary'
                },
                {
                    name: 'Info',
                    value: 'bg-info'
                },
                {
                    name: 'Dark',
                    value: 'bg-dark'
                },
                {
                    name: 'Warning',
                    value: 'bg-warning'
                },
            ],
            submitted: false,
            submit: false,
            newEventData: {},
            edit: {},
            deleteId: {},
            event: {
                title: "",
                category: "",
            },
            editevent: {
                editTitle: "",
                editcategory: "",
            },
        };
    },

    methods: {
        handleEvents(events) {
            this.currentEvents = events;
        },

        fetchMeetings: function (fetchInfo, successCallback, failureCallback) {
            return this.$store.dispatch('user/calendar', {id: this.id || this.$route.params.id, query: { from: fetchInfo?.startStr, to: fetchInfo?.endStr, with: ['partner'] }})
                .then(response => {
                    successCallback(response?.map(item => {
                        item.allDay = item.all_day;
                        return item;
                    }))
                })
                .catch(e => {
                    failureCallback(e)
                })
            // It comes from the interaction package, then moved to the calendar package.
/*            return this.$store.dispatch('meeting/mine', {id: this.id || this.$route.params.id, query: { from: fetchInfo?.startStr, to: fetchInfo?.endStr, with: ['partner'] }})
                .then(response => {
                    successCallback(response)
                })
                .catch(e => {
                    failureCallback(e)
                })*/
        }
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler: function (user) {
                if (user?.id) {
                    this.$refs.fullCalendar?.getApi()?.refetchEvents()
                }
            },
        }
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="app-calendar">
            <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
            ></FullCalendar>
        </div>
    </b-overlay>
</template>

<style scoped>

</style>

<script>
import StatusBadge from "../status-badge.vue";
import SimpleTable from "../simple-table.vue";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import _ from "lodash";
import TransitionToggle from "../../transitions/toggle.vue";
import ContractForm from "../../forms/contract-form.vue";
import OfferForm from "../../forms/offer-form.vue";
import ProjectStepInteractionForm from "../../forms/project-step-interaction-form.vue";
import MeetingForm from "../../forms/meeting-form.vue";
import PartnerModal from "../modals/partner-modal.vue";
import RelatedUserManager from "../related-user-manager.vue";

export default {
    name: "task-card",

    components: {
        RelatedUserManager,
        PartnerModal,
        MeetingForm,
        ProjectStepInteractionForm,
        OfferForm,
        ContractForm,
        TransitionToggle, SimpleTable, StatusBadge},

    data: function () {
        return {
            taskType: 'all',
            closed: [],
            search: '',
            tempSearch: '',
            searchFocused: false,
            type: 'tasks',
            selected: null,
            showModal: false,
            modalTitle: '',
            interaction: null,
            currentTask: null,
            partnerId: null,
            partnerModal: false,
            modalTab: 'partner'
        };
    },

    computed: {
        ...mapGetters({
            loading: 'user/loadingTasks',
            taskLoading: 'task/loading',
            tasks: 'user/tasks',
            user: 'user/profile',
            authenticated: 'user/authenticated',
            statuses: 'taskStatus/all',
            projectStepInteraction: 'projectStepInteraction/item'
        }),

        own: function () {
            return this.user?.id === this.authenticated?.id;
        },

        taskFields: function () {
            let fields = [
                {
                    key: "name",
                    label: this.$t('tasks::tasks.columns.name').ucFirst(),
                    formatter: (value, index, item) => item.translation_key ? this.$t(item.translation_key).ucFirst() : value,
                    thClass: '',
                    tdClass: (value, index, item) => { return {'font-weight-bold': true, 'bg-soft-danger text-dark': item['expired'] && !item.closed_at} },
                    sortable: true
                }
            ];

            if (this.configuration?.tasks.enable_priorities) {
                fields.push({
                    key: "priority",
                    label: this.$t('tasks::tasks.relationships.priority').ucFirst(),
                    formatter: (value, index, item) => value ? value : item.priority_id,
                    tdClass: (value, index, item) => { return {'bg-soft-danger text-dark': item['expired'] && !item.closed_at} },
                    thClass: '',
                    sortable: true
                })
            }
            if (this.configuration?.tasks.enable_statuses) {
                fields.push({
                    key: "status",
                    label: this.$t('tasks::tasks.relationships.status').ucFirst(),
                    formatter: (value, index, item) => value ? value : item.status_id,
                    tdClass: (value, index, item) => { return {'bg-soft-danger text-dark': item['expired'] && !item.closed_at} },
                    thClass: '',
                    sortable: true
                })
            }

            fields = [
                ...fields,
                {
                    key: "client",
                    label: this.$t('tasks::tasks.relationships.client').ucFirst(),
                    formatter: (value) => value?.name,
                    tdClass: (value, index, item) => { return {'bg-soft-danger text-dark': item['expired'] && !item.closed_at} },
                    thClass: '',
                    sortable: true
                },
                {
                    key: "type",
                    label: this.$t('tasks::tasks.relationships.type').ucFirst(),
                    formatter: (value, index, item) => value ? (value.translation_key ? this.$t(value.translation_key) : value.name) : item.type_id,
                    tdClass: (value, index, item) => { return {'bg-soft-danger text-dark': item['expired'] && !item.closed_at} },
                    thClass: '',
                    sortable: true
                },
                {
                    key: "deadline",
                    label: this.$t('tasks::tasks.columns.deadline').ucFirst(),
                    formatter: (value) => value?.printDate(),
                    tdClass: (value, index, item) => { return {'bg-soft-danger text-danger font-weight-bold': item['expired'] && !item.closed_at} },
                    thClass: '',
                    sortable: true
                }
            ];

            if (this.type === 'tasks') {
                fields.push({
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: (value, index, item) => { return {'bg-soft-danger text-danger font-weight-bold': item['expired'] && !item.closed_at} },
                    sortable: false
                });
            }

            return fields;
        },

        searchDebounce: {
            get: function () {
                return this.search;
            },
            set: function (value) {
                this.tempSearch = value;
                _.debounce( (value) => {
                    if (this.tempSearch === value) {
                        this.search = value;
                        // this.$emit('update:modelValue', this.search);
                    }
                }, 100)(value);
            },
        },

        filtered: function () {
            let base = this.tasks?.filter(task => !this.closed.includes(task.id));

            switch (this.taskType) {
                case 'all':
                    break;
                case 'as_responsible':
                    base = base.filter(i => i.is_responsible);
                    break;
                case 'as_assigned':
                    base = base.filter(i => i.is_assigned);
                    break;
                case 'as_owner':
                    base = base.filter(i => i.is_owner);
                    break;
                case 'as_follower':
                    base = base.filter(i => i.is_follower);
                    break;
                case 'as_creator':
                    base = base.filter(i => i.is_creator);
                    break;
                case 'as_contributor':
                    base = base.filter(i => i.is_contributor);
                    break;
            }

            if (!this.search) {
                return base;
            }

            return base?.filter(item => {
                const lc = this.search.toLowerCase();
                return (item.name.toLowerCase().indexOf(lc) > -1)
                    || (item.client?.name?.toLowerCase().indexOf(lc) > -1)
                    || (
                        item.type?.translation_key
                            ? this.$t(item.type.translation_key).toLowerCase().indexOf(lc) > -1
                            : item.type?.name?.toLowerCase().indexOf(lc) > -1
                    )
            });
        },
    },

    methods: {
        select: function (id) {
            this.selected = id;
        },

        toggleType: function () {
            this.type = this.type === 'tasks' ? 'closedTasks' : 'tasks';
            this.fetch();
        },

        fetch: function () {
            this.$store.dispatch(`user/${this.type}`, this.user.id);
        },

        openTask: function (task) {
            const date = new Date().formattedDate();

            if (!task.interaction && !task.event_type) {
                if (task.controller) {
                    if (task.controller.decision) {
                        const opportunities = task.controller.decision.opportunities;

                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.confirm_title'),
                            text: this.$t('base.confirm_message'),
                            showCancelButton: true,
                            showConfirmButton: true,
                            showDenyButton: true,
                            customClass: {
                                icon: 'text-warning border-warning',
                                confirmButton: 'btn btn-' + opportunities[0].color,
                                denyButton: 'btn btn-' +  + opportunities[1].color
                            },
                            confirmButtonText: opportunities[0].text.ucFirst(),
                            denyButtonText: opportunities[1].text.ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            const edited = {};
                            if (result.isConfirmed) {
                                task.controller.setter.forEach(setter => {
                                    edited[setter] = opportunities[0].value;
                                });
                                this.$emit('edit', edited);
                                this.$store.dispatch('task/close', {id: task.id, query: this.query}).then(() => {
                                    this.$store.dispatch('projectStep/removeTask', task.id);
                                });
                            }
                            if(result.isDenied) {
                                task.controller.setter.forEach(setter => {
                                    edited[setter] = opportunities[1].value;
                                });
                                this.$emit('edit', edited);
                                this.$store.dispatch('task/close', {id: task.id, query: this.query}).then(() => {
                                    this.$store.dispatch('projectStep/removeTask', task.id);
                                });
                            }
                        });
                    }
                } else {
                    this.closeTask(task.id);
                }
            }

            switch (task.interaction?.translation_key) {
                case 'interactions::interactions.interactions.phone':
                    this.currentTask = task;
                    this.interaction = task.interaction;
                    this.modalTitle = task.name;
                    this.showModal = true;
                    break;
                case 'interactions::interactions.interactions.meeting':
                    if (task.controller) {
                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.what_happened').ucFirst(),
                            text: this.$t('interactions::meetings.how_the_trial_ended').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            showDenyButton: true,
                            customClass: {
                                icon: 'text-danger border-danger',
                                confirmButton: 'btn btn-success',
                                denyButton: 'btn btn-danger',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('base.successful').ucFirst(),
                            denyButtonText: this.$t('base.failed').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.$store.dispatch('meeting/realize', { id: task.event_id, data: { realized_at: (new Date()).formattedDate(), successful: true} }).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                            if (result.isDenied) {
                                this.$store.dispatch('meeting/realize', { id: task.event_id, data: { realized_at: (new Date()).formattedDate(), successful: false} }).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                        });
                    } else {
                        this.currentTask = task;
                        this.interaction = task.interaction;
                        this.modalTitle = task.name;
                        this.showModal = true;
                    }
                    break;
                case 'interactions::interactions.interactions.offer':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.what_happened').ucFirst(),
                            text: this.$t('projects::offers.customer_response').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            showDenyButton: true,
                            customClass: {
                                icon: 'text-danger border-danger',
                                confirmButton: 'btn btn-success',
                                denyButton: 'btn btn-danger',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('base.accepted').ucFirst(),
                            denyButtonText: this.$t('base.refused').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.$store.dispatch('offer/accept', task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                            if (result.isDenied) {
                                this.$store.dispatch('offer/reject', task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                });
                            }
                        });
                    } else {
                        this.currentTask = task;
                        this.interaction = task.interaction;
                        this.modalTitle = task.name;
                        this.showModal = true;
                    }
                    break;
                case 'interactions::interactions.interactions.contract':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'warning',
                            title: this.$t('base.what_happened').ucFirst(),
                            text: this.$t('projects::offers.customer_response').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            customClass: {
                                icon: 'text-danger border-danger',
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('projects::contracts.contract_is_signed').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                return this.$store.dispatch(`contract/signed`, task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            {},
                                            task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('meeting/setItem', {});
                                        this.$emit('refresh')
                                    });
                                })
                            }
                        });
                    } else {
                        this.currentTask = task;
                        this.interaction = task.interaction;
                        this.modalTitle = task.name;
                        this.showModal = true;
                    }
                    break;
                case 'interactions::interactions.interactions.implement':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'success',
                            title: this.$t('projects::implements.the_service_is_completed').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            customClass: {
                                icon: 'text-success border-success',
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('projects::implements.service_completed').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                return this.$store.dispatch(`implementPart/complete`, task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            { id: task.id },
                                            // task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('contract/setItem', {});
                                        this.$emit('refresh')
                                    });
                                })
                            }
                        });
                    }
                    break;
                case 'interactions::interactions.interactions.document':
                    if (task.event_id) {
                        Swal.fire({
                            icon: 'success',
                            title: this.$t('projects::documents.is_the_documentation_ready').ucFirst(),
                            showCancelButton: true,
                            showConfirmButton: true,
                            customClass: {
                                icon: 'text-success border-success',
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-secondary'
                            },
                            confirmButtonText: this.$t('projects::documents.the_documentation_ready').ucFirst(),
                            cancelButtonText: this.$t('base.cancel').ucFirst(),
                        }).then(result => {
                            if (result.isConfirmed) {
                                return this.$store.dispatch(`document/realize`, task.event_id).then(() => {
                                    this.$store.dispatch('task/update',
                                        Object.assign(
                                            { id: task.id },
                                            // task,
                                            { closed_at: date }
                                        )
                                    ).then(() => {
                                        this.$store.dispatch('projectStep/removeTask', task.id);
                                        this.$store.dispatch('projectStepInteraction/setItem', {})
                                        this.$store.dispatch('contract/setItem', {});
                                        this.$emit('refresh')
                                    });
                                })
                            }
                        });
                    }
                    break;
                default:
                    switch (task.event_type) {
                        case 'Appon\\Partners\\Models\\Partner':
                            this.currentTask = task;
                            this.interaction = task.interaction;
                            this.modalTitle = task.name;
                            this.modalTab = 'partner';
                            this.partnerModal = true;
                            break;
                        case 'Appon\\Partners\\Models\\Contact':
                            this.currentTask = task;
                            this.interaction = task.interaction;
                            this.modalTitle = task.name;
                            this.modalTab = 'contact';
                            this.partnerModal = true;
                            break;
                    }
            }

        },

        closeTask: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-warning border-warning',
                    confirmButton: 'btn btn-warning',
                    cancelButton: 'btn btn-warning'
                },
                confirmButtonText: this.$t('base.close_it').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('task/close', {id: id, query: this.query}).then(() => {
                        this.$store.dispatch('projectStep/removeTask', id);
                        this.$store.dispatch(`user/removeTaskFromList`, id);
                    });
                }
            });
        },

        saved: function (event) {
            this.partnerModal = false;
            this.showModal = false;
            const date = new Date().formattedDate();
            switch (this.currentTask.interaction?.translation_key) {
                case 'interactions::interactions.interactions.phone':
                    this.$store.dispatch('task/update',
                        Object.assign(
                            {},
                            this.currentTask,
                            { closed_at: date, event_type: 'Appon\\Projects\\Models\\ProjectStepInteraction', event_id: event.id }
                        )
                    ).then(() => {
                        this.fetch();
                    });
                    break;
                case 'interactions::interactions.interactions.meeting':
                    this.$store.dispatch(`projectStepInteraction/create`, {
                        project_step_id: this.projectStep?.id,
                        interaction_id: this.interaction?.id,
                        meeting: Object.assign({}, event, {
                            partner_type: this.project?.target_type,
                            partner_id: this.project?.target_id
                        })
                    }).then(projectStepInteraction => {
                        this.$store.dispatch('task/update',
                            Object.assign(
                                {},
                                this.currentTask,
                                { closed_at: date, event_type: projectStepInteraction.event_type, event_id: projectStepInteraction.event_id }
                            )
                        ).then(() => {
                            this.$store.dispatch('projectStepInteraction/setItem', {})
                            this.$store.dispatch('meeting/setItem', {});
                            this.fetch();
                        });
                    });
                    break;
                case 'interactions::interactions.interactions.offer':
                    this.$store.dispatch(`projectStepInteraction/create`, {
                        project_step_id: this.projectStep?.id,
                        interaction_id: this.interaction?.id,
                        offer: Object.assign({}, event, {
                            project_id: this.project?.id
                        })
                    }).then(projectStepInteraction => {
                        this.$store.dispatch('task/update',
                            Object.assign(
                                {},
                                this.currentTask,
                                {
                                    closed_at: date,
                                    event_type: projectStepInteraction.event_type,
                                    event_id: projectStepInteraction.event_id
                                }
                            )
                        ).then(() => {
                            this.$store.dispatch('projectStepInteraction/setItem', {})
                            this.$store.dispatch('offer/setItem', {});
                            this.fetch();
                        });
                    });
                    break;
                case 'interactions::interactions.interactions.contract':
                    this.$store.dispatch(`projectStepInteraction/create`, {
                        project_step_id: this.projectStep?.id,
                        interaction_id: this.interaction?.id,
                        contract: Object.assign({}, event, {
                            project_id: this.project?.id
                        })
                    }).then(projectStepInteraction => {
                        this.$store.dispatch('task/update',
                            Object.assign(
                                {},
                                this.currentTask,
                                {
                                    closed_at: date,
                                    event_type: projectStepInteraction.event_type,
                                    event_id: projectStepInteraction.event_id
                                }
                            )
                        ).then(() => {
                            this.$store.dispatch('projectStepInteraction/setItem', {})
                            this.$store.dispatch('offer/setItem', {});
                            this.fetch();
                        });
                    });
                    break;
                default:
                    this.fetch();
                    break;
            }
        },

        clear: function () {
            this.showModal = false;
            this.modalTitle = '';
            this.interaction = null;
            this.currentTask = null;
        },

        /*closeTask: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-warning border-warning',
                    confirmButton: 'btn btn-warning',
                    cancelButton: 'btn btn-warning'
                },
                confirmButtonText: this.$t('base.close_it').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('task/close', {id: id, query: this.query}).then(() => {
                        this.closed.push(id);
                    });
                }
            });
        },*/

        changeStatus: function (item, status) {
            this.$store.dispatch('task/setItem', item)
            this.$store.dispatch('task/update', Object.assign({}, item, {status_id: status.id})).then(() => {
                this.$store.dispatch('user/updateTaskStatus', {id: item.id, status: status});
                this.selected = null;
            });
        },

        changeTaskAssigned: function (item, user) {
            this.$store.dispatch('task/setItem', item)
            this.$store.dispatch('task/update', Object.assign({}, item, {assigned_type: 'App\\Models\\User', assigned_id: user.id})).then(() => {
                this.$store.dispatch('user/updateTaskAssigned', {id: item.id, user: user});
                this.selected = null;
            });
        }
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler: function (user) {
                if (user?.id) {
                    this.fetch();
                }
            },
        }
    },

    created: function () {
        this.$store.dispatch('taskStatus/all');
    },
}
</script>

<template>
    <b-overlay :show="loading || taskLoading">
        <div class="row">
            <div class="col-xl-3">
                <div class="bg-white rounded p-2 mb-3">
                    <b-input-group>
                        <b-input-group-text class="bg-white border-right-0" :class="{focused: searchFocused}">
                            <i class="fas fa-search"></i>
                        </b-input-group-text>
                        <input class="form-control border-left-0"
                               type="search"
                               v-model="searchDebounce"
                               :placeholder="$t('base.search').ucFirst()"
                               @focusin="searchFocused = true"
                               @blur="searchFocused = false"
                        />
                    </b-input-group>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="bg-white rounded p-2 mb-3">
                    <b-button class="me-2" :variant="taskType === 'all' ? 'primary' : 'outline-secondary'" @click="taskType = 'all'">{{ $t('base.all').ucFirst() }}</b-button>
                    <b-button class="me-2" :variant="taskType === 'as_assigned' ? 'primary' : 'outline-secondary'" @click="taskType = 'as_assigned'">{{ $t('tasks::tasks.relationships.assigned').ucFirst() }}</b-button>
                    <b-button class="me-2" :variant="taskType === 'as_owner' ? 'primary' : 'outline-secondary'" @click="taskType = 'as_owner'">{{ $t('tasks::tasks.relationships.owner').ucFirst() }}</b-button>
                    <b-button class="me-2" :variant="taskType === 'as_responsible' ? 'primary' : 'outline-secondary'" @click="taskType = 'as_responsible'">{{ $t('tasks::tasks.relationships.responsibles').ucFirst() }}</b-button>
                    <b-button class="me-2" :variant="taskType === 'as_contributor' ? 'primary' : 'outline-secondary'" @click="taskType = 'as_contributor'">{{ $t('tasks::tasks.relationships.contributors').ucFirst() }}</b-button>
                    <b-button class="me-2" :variant="taskType === 'as_creator' ? 'primary' : 'outline-secondary'" @click="taskType = 'as_creator'">{{ $t('tasks::tasks.relationships.creator').ucFirst() }}</b-button>
                    <b-button :variant="taskType === 'as_follower' ? 'primary' : 'outline-secondary'" @click="taskType = 'as_follower'">{{ $t('tasks::tasks.relationships.followers').ucFirst() }}</b-button>
                </div>
            </div>
            <div class="col-xl-1">
                <div class="rounded p-2 mb-3 text-center" :class="{'bg-white': type === 'tasks', 'bg-soft-warning': type !== 'tasks'}" style="transition: .3s">
                    <b-button :variant="type === 'tasks' ? 'white' : 'soft-warning'" @click="toggleType">
                        <i class="fas fa-archive font-size-18"></i>
                    </b-button>
                </div>
            </div>
        </div>
        <div class="bg-light rounded mb-4">
            <simple-table :items="filtered || []" :fields="taskFields">
                <template #actions="{item}">
                    <li v-if="!item.closed_at" class="list-inline-item">
                        <a
                            @click.prevent="openTask(item)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="$t('base.close_it').ucFirst()"
                            class="px-2 text-info cursor-pointer"
                        ><i class="bx bx-check font-size-18"></i
                        ></a>
                    </li>
                    <li class="list-inline-item">
                        <related-user-manager
                            class="text-secondary"
                            v-model="item.assigned"
                            store="user"
                            :list="false"
                            :return-user="true"
                            @update:modelValue="(assigned) => changeTaskAssigned(item, assigned)"
                        ></related-user-manager>
                    </li>
                </template>
                <template #name="{formatted, item}">
                    <router-link class="text-dark" :to="$t('routes./tasks/accessible-tasks/:id(\\d+)', {id: item.id}).rmValidation()">
                        {{ formatted }}
                    </router-link>
                </template>
                <template #priority="{ value }">
                    <div v-if="value" :style="{color: value.color}">
                        <i :class="value.icon"></i>
                    </div>
                </template>
                <template #status="{ value, item }">
                    <div class="" @mouseleave="selected = null">
                        <span :class="{'cursor-pointer': own}" @click="select(item.id)">
                            <span v-if="value?.icon" :style="{color: value.color}" class="me-2">
                                <i :class="value.icon"></i>
                            </span>
                            <status-badge :bg-color="value?.color">{{ value?.translation_key ? $t(value.translation_key) : value?.name }}</status-badge>
                        </span>
                        <transition-toggle>
                            <div class="p-1">
                                <div v-if="selected === item.id" class="p-2 status-list position-absolute bg-light border rounded overflow-hidden">
                                    <ul class="list-unstyled">
                                        <template v-for="(status, index) in statuses" :key="index">
                                            <li class="cursor-pointer mb-1" v-if="value.id !== status.id" @click="changeStatus(item, status)">
                                            <span v-if="status?.icon" :style="{color: status.color}" class="me-2">
                                                <i :class="status.icon"></i>
                                            </span>
                                                <status-badge :bg-color="status?.color">{{ status?.translation_key ? $t(status.translation_key) : status?.name }}</status-badge>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </transition-toggle>
                    </div>
                </template>
            </simple-table>
        </div>
        <b-modal size="xl" body-bg-variant="light" body-class="rounded" v-model="showModal" :title="modalTitle" @close="clear" hide-footer>
            <template v-if="interaction?.translation_key === 'interactions::interactions.interactions.phone'">
                <project-step-interaction-form v-if="showModal" :key="showModal"
                                               :only-successful="true"
                                               :redirect="false"
                                               :project-step-interaction="projectStepInteraction"
                                               :project-step="currentTask.project_step"
                                               :interaction="interaction"
                                               @saved="saved"
                >
                </project-step-interaction-form>
            </template>
            <template v-else-if="interaction?.translation_key === 'interactions::interactions.interactions.meeting'">
                <meeting-form
                    :meeting="meeting"
                    :redirect="!fix"
                    :fixed="true"
                    :own="true"
                    :doNotSave="true"
                    @updated="(newMeeting) => saved(newMeeting)"
                ></meeting-form>
            </template>
            <template v-else-if="interaction?.translation_key === 'interactions::interactions.interactions.offer'">
                <offer-form
                    :offer="offer"
                    :redirect="!fix"
                    :fixed="true"
                    :doNotSave="true"
                    @updated="(newOffer) => saved(newOffer)">
                </offer-form>
            </template>
            <template v-else-if="interaction?.translation_key === 'interactions::interactions.interactions.contract'">
                <contract-form
                    :contract="contract"
                    :redirect="!fix"
                    :fixed="true"
                    :doNotSave="true"
                    @updated="(newContract) => saved(newContract)">

                </contract-form>
            </template>
        </b-modal>
        <partner-modal v-model="partnerModal" :id="currentTask?.client?.id" :project-step="currentTask?.project_step" :title="currentTask?.name" :tab="modalTab" danger @modified="saved">

        </partner-modal>
    </b-overlay>
</template>

<style  lang="scss" scoped>
@use "@/assets/scss/bootstrap.scss" as colors;
.status-list {
    z-index: 1;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
    border-width: 1px;
    border-style: solid;
    border-color: colors.$primary
}

</style>

<script>
import {mapGetters} from "vuex";

export default {
    name: "personal-performance",

    props: {
        user: {
            type: Object
        },

        year: {
            type: [String, Number],
            default: () => {
                return new Date().getFullYear();
            }
        },
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale',
            years: 'performance/years',
            yearly: 'performance/yearly',
            monthly: 'performance/monthly'
        }),

        months: function () {
            return Object.keys(this.monthly?.[this.year] || {}).reverse();
        },
    },

    data: function () {
        return {
            performance: null,
            reverseYears: [],
            target_values: {},
            offer_target_values: {},
            contract_target_values: {},
        };
    },

    methods: {
        color: function (value = 0, target) {
            if (value >= target) {
                return 'success';
            } else if (value > target * 0.75 && value < target * 0.9) {
                return 'warning';
            } else if (value <= target * 0.75) {
                return 'danger';
            }

            return 'primary';
        },

        text: function (value, target) {
            return {
                'text-success': (target === null) || (value >= target),
                'text-danger': target && (value <= target * 0.75),
                'text-warning': value > target * 0.75 && value < target * 0.9,
            };
        },

        fetchMonthly: function () {
            if (this.user?.id) {
                this.$store.dispatch('performance/monthly', this.user?.id);
            }
        },

        fetchYearly: function () {
            if (this.user?.id) {
                this.$store.dispatch('performance/yearly', this.user?.id);
            }
        },
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler:function(user) {
                if (user?.id) {
                    this.fetchMonthly();
                    this.fetchYearly();
                    this.$store.dispatch('performance/personalPerformance', user.id)
                    .then(response => {
                        this.performance = response.data;
                        this.years = response.years;
                        this.reverseYears = JSON.parse(JSON.stringify(response.years)).reverse();
                        this.months = response.months;
                        const targetValues = {};
                        response?.target_values.forEach(targetValue => {
                            if (targetValue?.interaction_name === 'offer' || targetValue?.interaction_name === 'contract') {
                                if (!targetValues[targetValue?.interaction_name]) {
                                    targetValues[targetValue?.interaction_name] = [];
                                }

                                targetValues[targetValue?.interaction_name].push(targetValue);
                                this[targetValue?.interaction_name + '_target_values'][targetValue.currency_code] = targetValue;
                            } else {
                                targetValues[targetValue?.interaction_name || 'scores'] = targetValue;
                            }
                        });
                        this.target_values = targetValues;
                    });
                }
            }
        },
    }
}
</script>

<template>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title text-center text-muted">
                        <i class="fas fa-calendar-alt me-2"></i>
                        <span>{{ $t('dates.yearly').ucFirst() }}</span>
                    </h5>
                </div>
                <div class="card-body">
                    <table class="table align-middle table-striped table-hover table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{ year }}</th>
                            <th class="text-center">{{ $tc('projects::target_values.target_value', 2).ucFirst() }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <span class="d-inline-flex font-size-20 align-items-center justify-content-center text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary cube-40px"
                                      :title="$t('projects::performance.scores').ucFirst()"
                                      :class="'text-' + color(yearly?.[year]?.total?.score, yearly?.[year]?.target_score) + ' bg-soft-' + color(yearly?.[year]?.total?.score, yearly?.[year]?.target_score)"
                                >
                                    <i class="fas fa-check-double"></i>
                                </span>
                            </td>
                            <td>
                                <span class="font-weight-bold font-size-16" :class="text(yearly?.[year]?.total?.score, yearly?.[year]?.target_score)">{{ yearly?.[year]?.total?.score }}</span>
                            </td>
                            <td class="text-end">
                                <span :title="$tc('projects::target_values.yearly_target_value', 1).ucFirst()" class="font-size-12 bull-eye ms-2">
                                    {{ yearly?.[year]?.target_score }}
                                </span>
                            </td>
                        </tr>
                        <tr v-for="(interaction, key) in yearly?.[year]?.interactions" :key="key">
                            <td>
                                <span class="d-inline-flex font-size-20 align-items-center justify-content-center text-center p-2 border border-white rounded bg-gradient bg-soft-primary text-primary cube-40px"
                                      :title="$tc(interaction?.translation_key, 2).ucFirst()"
                                      :class="'text-' + color(interaction?.value, interaction?.target_value) + ' bg-soft-' + color(interaction?.value, interaction?.target_value)"
                                >
                                    <i :class="interaction?.icon"></i>
                                </span>
                            </td>
                            <td>
                                <span class="font-weight-bold font-size-16" :class="text(interaction?.value, interaction?.target_value)">{{ interaction?.is_financial ? (interaction?.value?.printCurrency(currency, locale) || 0) : interaction?.value }}</span>
                            </td>
                            <td class="text-end">
                                <span v-if="interaction?.target_value && !interaction?.is_financial" :title="$tc('projects::target_values.yearly_target_value', 1).ucFirst()" class="font-size-12 bull-eye ms-2">
                                    {{ interaction?.target_value }}
                                </span>
                                <small class="font-weight-bold text-muted" v-else-if="interaction?.target_value && interaction?.is_financial">
                                    {{ interaction?.target_value?.printCurrency(currency, locale) || 0 }}
                                </small>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 col-lg-6" v-for="(month) in months" :key="month">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                <div class="d-flex align-items-center">
                                    <div class="me-3">
                                        <i class="fas fa-calendar-alt font-size-34 text-muted opacity-50"></i>
                                    </div>
                                    <div>
                                        <p class="mb-0">
                                            <small>{{ year }}.</small>
                                        </p>
                                        <h5 class="mb-0">{{ month.printMonth() }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-2">
                            <table class="table table-sm align-middle table-striped table-hover table-nowrap mb-0">
                                <tbody>
                                <tr>
                                    <td>
                                        <span class="font-size-12 d-inline-flex align-items-center justify-content-center text-center border border-white rounded bg-gradient cube-24px"
                                              :class="'text-' + color(monthly?.[year]?.[month]?.total?.score, monthly?.[year]?.[month]?.target_score) + ' bg-soft-' + color(monthly?.[year]?.[month]?.total?.score, monthly?.[year]?.[month]?.target_score)"
                                              :title="$t('projects::performance.scores').ucFirst()"
                                        >
                                            <i class="fas fa-check-double"></i>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold font-size-16" :class="'text-' + color(monthly?.[year]?.[month]?.total?.score, monthly?.[year]?.[month]?.target_score)">{{ monthly?.[year]?.[month]?.total?.score }}</span>
                                    </td>
                                    <td class="text-end">
                                        <span :title="$tc('projects::target_values.yearly_target_value', 1).ucFirst()"
                                              class="font-size-12 bull-eye ms-2"
                                              :class="'bull-eye-' + color(monthly?.[year]?.[month]?.total?.score, monthly?.[year]?.[month]?.target_score)"
                                        >
                                            {{ monthly?.[year]?.[month]?.target_score }}
                                        </span>
                                    </td>
                                </tr>
                                <tr v-for="(interaction, key) in monthly?.[year]?.[month]?.interactions" :key="key">
                                    <td>
                                        <span class="font-size-12 d-inline-flex align-items-center justify-content-center text-center border border-white rounded bg-gradient cube-24px"
                                              :class="'text-' + color(interaction?.value, interaction?.target_value) + ' bg-soft-' + color(interaction?.value, interaction?.target_value)"
                                              :title="$t(interaction?.translation_key).ucFirst()"
                                        >
                                            <i :class="interaction?.icon"></i>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="font-weight-bold font-size-16" :class="'text-' + color(interaction?.value, interaction?.target_value)">{{ interaction?.is_financial ? (interaction?.value?.printCurrency(currency, locale) || 0) : interaction?.value }}</span>
                                    </td>
                                    <td class="text-end">
                                        <span v-if="interaction?.target_value && !interaction?.is_financial" :title="$tc('projects::target_values.monthly_target_value', 1).ucFirst()"
                                              class="font-size-12 bull-eye ms-2"
                                              :class="'bull-eye-' + color(interaction?.value, interaction?.target_value)"
                                        >
                                            {{ interaction?.target_value }}
                                        </span>
                                        <small class="font-weight-bold text-muted" v-else-if="interaction?.target_value && interaction?.is_financial">
                                            {{ interaction?.target_value?.printCurrency(currency, locale) || 0 }}
                                        </small>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cube-40px {
    width: 40px;
    height: 40px;
}

.cube-20px {
    width: 20px;
    height: 20px;
}

.cube-24px {
    width: 24px;
    height: 24px;
}
</style>

<script>
import PartnerGridItem from "./partner-grid-item.vue";
import _ from "lodash";
import TransitionToggle from "../../transitions/toggle.vue";

export default {
    name: "partner-grid",

    components: {TransitionToggle, PartnerGridItem},

    props: {
        items: {
            type: Array,
            default: () => { return []; }
        }
    },

    computed: {
        filtered: function () {
            return this.items?.filter(item => item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
        },

        list: function () {
            return this.filtered.slice(0,3);
        },

        searchDebounce: {
            get: function () {
                return this.search;
            },
            set: function (value) {
                this.loading = true;
                this.tempSearch = value;
                _.debounce( (value) => {
                    if (this.tempSearch === value) {
                        this.search = value;
                        // this.$emit('update:modelValue', this.search);
                        this.loading = false;
                    }
                }, 700)(value);
            },
        },
    },

    data: function () {
        return {
            search: '',
            tempSearch: '',
            searchFocused: false,
            loading: false
        };
    },
}
</script>

<template>
<div class="partner-grid">
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-3">
            <b-input-group>
                <b-input-group-text class="bg-white border-right-0" :class="{focused: searchFocused}">
                    <i class="fas fa-search"></i>
                </b-input-group-text>
                <input class="form-control border-left-0"
                       type="search"
                       v-model="searchDebounce"
                       :placeholder="$t('base.search').ucFirst()"
                       @focusin="searchFocused = true"
                       @blur="searchFocused = false"
                />
            </b-input-group>
            <transition-toggle>
                <div v-if="loading || searchFocused" class="autocomplete-list w-100 position-absolute bg-light border rounded overflow-hidden">
                    <b-overlay :show="loading" wrap-tag="div" overlay-tag="div">
                        <ul class="list-unstyled mb-0">
                            <li class="cursor-pointer ps-5 pe-2 py-1 font-size-12" @mousedown.stop="searchDebounce = item.name" v-for="(item, index) in list" :key="index">{{ item.name }}</li>
                        </ul>
                    </b-overlay>
                </div>
            </transition-toggle>
        </div>
    </div>
    <b-overlay :show="loading">
        <div class="row">
            <div class="col-xl-3 col-sm-6 mb-4" v-for="(item, itemIndex) in filtered" :key="itemIndex">
                <partner-grid-item :partner="item"></partner-grid-item>
            </div>
        </div>
    </b-overlay>
</div>
</template>


<style lang="scss" scoped>
@use "@/assets/scss/bootstrap.scss" as colors;

.autocomplete-list {
    margin-top: 2px;
    z-index: 1;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
    border-width: 1px;
    border-style: solid;
    border-color: colors.$primary
}
li{
    &:hover {
        background-color: lighten(colors.$primary, 25%) !important;
    }
}
</style>

<script>
import {mapGetters} from "vuex";
import Avatar from "./avatar.vue";

export default {
    name: "sticky-note",
    components: {Avatar},

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        })
    },

    props: {
        title: {
            type: String
        },

        message: {
            type: String
        },

        due: {
            type: String
        },

        users: {
            type: Array
        }
    }
}
</script>

<template>
    <div class="sticky-note position-relative overflow-hidden">
        <slot name="header"></slot>
        <div class="body">
            <slot name="title">
                <h5 v-if="title">{{ title }}</h5>
            </slot>
            <slot name="message">
                <p v-if="message">{{ message }}</p>
            </slot>
        </div>
        <slot name="footer">
            <div class="d-flex justify-content-between align-items-center">
                <slot name="reference"></slot>
                <slot name="users">
                    <div class="avatar-group">
                        <div v-for="(user, index) in users" :key="index" class="avatar-group-item" :style="{borderColor: user?.color}">
                            <avatar :user="user" hover>
                                <template #hover>
                                    <span :title="user?.name"
                                          class="avatar-hover d-none avatar-title rounded-circle text-white font-size-16 user-select-none"
                                          :class="{'bg-soft-success': user.viewed_at, 'bg-soft-warning': !user.viewed_at}"
                                    >
                                        <i class="fas" :class="{'fa-check-circle text-success': user.viewed_at, 'fa-exclamation-triangle text-warning': !user.viewed_at}"></i>
                                    </span>
                                </template>
                            </avatar>
                        </div>
                    </div>
                </slot>
                <div v-if="due" class="text-end">
                    <small class="d-flex align-items-center">
                        <i class="bx bxs-bell-ring text-muted me-2"></i>
                        {{ due?.printDate(locale, {dateStyle: 'short', timeStyle: 'short'}) }}
                    </small>
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import FullCalendar from "@fullcalendar/vue3";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import huLocale from '@fullcalendar/core/locales/hu';

export default {
    name: "today",

    components: {
        FullCalendar,
    },

    computed: {
        ...mapGetters({
            loading: 'meeting/todayLoading',
            meetings: 'meeting/today'
        }),

    },

    data: function () {
        return {
            calendarOptions: {
                headerToolbar: {
                    left: "",
                    center: "title",
                    right: "",
                },
                locale: huLocale,
                plugins: [
                    bootstrapPlugin,
                    listPlugin,
                ],
                initialView: "listDay",
                themeSystem: "bootstrap",
                events: this.fetchMeetings,
                // editable: true,
                // droppable: true,

                eventsSet: this.handleEvents,
                weekends: true,
                dayMaxEvents: true,
            },
        }
    },

    methods: {
        handleEvents(events) {
            this.currentEvents = events;
        },

        fetchMeetings: function (fetchInfo, successCallback, failureCallback) {
            return this.$store.dispatch('user/calendar', {id: this.id || this.$route.params.id, query: { from: fetchInfo?.startStr, to: fetchInfo?.endStr, with: ['partner'] }})
                .then(response => {
                    successCallback(response?.map(item => {
                        item.allDay = item.all_day;
                        return item;
                    }))
                })
                .catch(e => {
                    failureCallback(e)
                })

            /*return this.$store.dispatch('meeting/today', {id: this.id || this.$route.params.id, query: { from: fetchInfo?.startStr, to: fetchInfo?.endStr, with: ['partner'] }})
                .then(response => {
                    successCallback(response)
                })
                .catch(e => {
                    failureCallback(e)
                })*/
        }
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card pt-4 overflow-hidden">
            <div class="app-calendar">
                <FullCalendar
                    :key="$route.params.id"
                    ref="fullCalendar"
                    :options="calendarOptions"
                ></FullCalendar>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>

</style>
